import React, { useState, useEffect, useRef } from "react";
import MasterTable from "../../Components/MasterTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Add,
  CloseOutlined,
  MoreVert,
  Close,
  ArrowBack,
  RefreshOutlined,
} from "@mui/icons-material";
import CDrawer from "../../Components/Container/CusDrawer";
import { axiosGet, axiosPost, axiosPostForm } from "../../lib/api";
import GridContainer from "../../Components/Container/GridContainer";
import Cookies from "js-cookie";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Chip,
  IconButton,
  TextField,
  capitalize,
  Drawer,
  Typography,
  Collapse,
  Badge,
} from "@mui/material";
import CreateButton from "../../Components/buttons/CreateButton";
import CInput from "../../Components/Container/CInput";
import AutoHideAlert from "../../Components/Container/AutoHideAlert";
import AlertDialog from "../../Components/Container/AlertDialog";
import CAlertDialog from "../../Components/Container/CAlertDialog";
import SearchFilter from "../../Components/buttons/SearchFilter";
import FilterButton from "../../Components/buttons/FilterButton";
import { capitalizeText } from "../../lib/config";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 160,
    },
  },
};

export default function Industry() {
  const ACCESS_TOKEN = Cookies.get("token");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openSts, setOpenSts] = useState(false);
  const [statusType, setStatusType] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [sortField, setSortField] = useState("createdDate");
  const [sortOrder, setSortOrder] = useState("descending");
  const [limit, setLimit] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataCount, setdataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerType, setOpenDrawerType] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [picture, setPicture] = useState(null);
  const [pictureUrl, setPictureUrl] = useState(null);
  const [pictureName, setPictureName] = useState(null);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [company, setCompany] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [industry, setIndustry] = useState("");
  const [industryData, setIndustryData] = useState([]);
  const [AOI, setAOI] = useState([]);
  const [AOIChip, setAOIChip] = useState([]);
  const [linkedIn, setLinkedIn] = useState("");
  const fileInputRef = useRef(null);
  const [postError, setPostError] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filtersList, setfiltersList] = useState(false);
  const [isIndusSelected, setIsIndusSelected] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [isAppStatusSelected, setIsAppStatusSelected] = useState(false);
  const [indusFilter, setIndusFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState(3);
  const [appStatusFilter, setAppStatusFilter] = useState(3);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rowBtn, setRowBtn] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [multistatusValue, setMultistatusValue] = useState("");
  const [openMulitiStatus, setOpenMultistatus] = useState(false);
  const [openMulitiDelete, setOpenMultiDelete] = useState(false);
  const open = Boolean(anchorEl);
  const areCheckboxesEnabled = true;

  // Function to get selected row data
  const getSelectedRowsData = () => {
    return rows.filter((row) => rowSelectionModel.includes(row.id));
  };

  useEffect(() => {
    const selectedRowsData = getSelectedRowsData();
    const selectedDatauniqids = selectedRowsData.map((row) => row.fullData);
    const cusID = selectedDatauniqids.map((row) => row.dataUniqId);
    setSelectedId(cusID);
    if (cusID.length > 0) {
      setRowBtn(true);
    } else {
      setRowBtn(false);
    }
    // let cusId=selectedDatauniqids.filter((data)=>data._id)
  }, [rowSelectionModel]);

  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      const { field, sort } = model[0];
      setSortField(field);
      setSortOrder(sort == "asc" ? "ascending" : "descending");
    } else {
      // Reset to default sorting if needed
      setSortField("createdDate");
      setSortOrder("descending");
    }
  };

  // Function to update searchValue when input changes
  const handleSearchInputChange = (input) => {
    setPageNumber(1);
    setSearchValue(input);
  };

  const handleRefresh = () => {
    setSearchValue("");
    fetchData();
  };

  // Toggel Drawer
  const toggleDrawer = (newOpen, type) => () => {
    // type 1 : Create
    // type 2 : Edit
    setOpenDrawer(newOpen);
    setOpenDrawerType(type);
    handleCloseMenu();
    if (type === 1) {
      setIndustry("");
    } else {
      setIndustry(singleData.industryName);
    }
  };

  const handleSaveClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    const fullData = item.fullData;
    setSingleData(fullData);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Open Status change warning box
  const handleStatusOpen = () => {
    setOpenActive(true);
    handleCloseMenu();
  };

  const handleDeleteOpen = () => {
    setOpenDel(true);
    handleCloseMenu();
  };

  const handleMultiDelete = () => {
    setOpenMultiDelete(true);
  };
  // open multi status waring
  const handleMultiStatus = (event) => {
    setOpenMultistatus(true);
    setMultistatusValue(event.target.value);
  };

  // Handler to log the page number on page change
  const handlePageChange = (model, newPage) => {
    let page = model.page + 1;
    let pageSize = model.pageSize;
    setPageNumber(page);
    setLimit(pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setLimit(newPageSize);
    setPageNumber(0);
  };

  // Filter Functions Start
  const handleStatusType = (value) => {
    setPageNumber(1);
    setStatusFilter(value);
    setIsStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };
  // Filter Functions End

  const fetchData = async () => {
    setIsLoading(true);
    axiosGet
      .get(
        `industrymaster/get/industries/by/adminpanel?userToken=${ACCESS_TOKEN}&page=${pageNumber}&limit=${limit}&isAll=${isAll}&sortField=${sortField}&sortOrder=${sortOrder}&industryName=${searchValue}&activeStatus=${
          statusFilter == 3 ? "" : statusFilter
        }`
      )
      // &activeStatus=${statusFilter == 3 ?"":statusFilter}
      .then((response) => {
        if (response.data.message === "Industries retrieved successfully") {
          setData(response.data.data);
          setdataCount(response.data.totalItems);
          setPageNumber(pageNumber === 0 ? 1 : pageNumber);
          setPageCount(response.data.total_pages);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setPostError(response.data.error);
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setAlertMessage("Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    pageNumber,
    limit,
    searchValue,
    statusFilter,
    appStatusFilter,
    sortOrder,
    sortField,
  ]);

  // Funtion for create new data or edit existing data
  const handleSubmit = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setIsLoading(true);
    const jsonData = {
      industryName: industry,
      createdBy: "admin",
      modifiedBy: "admin",
      userToken: ACCESS_TOKEN,
      dataUniqId: singleData.dataUniqId,
    };

    try {
      if (openDrawerType == 1) {
        axiosPost
          .post(`industrymaster/create`, jsonData)
          .then((response) => {
            // Handle the successful POST response here
            if (
              response.data.message ===
              "IndustryMaster record created successfully"
            ) {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage("Industry created successfully");
              // Cookies.remove("uuid");
              setOpenDrawer(false);
              setIsLoading(false);
              fetchData();
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setIsLoading(false);
              setAlertMessage(response.data.message);
              setPostError(response.data.message);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
          });
      } else {
        axiosPost
          .put(`industrymaster/update`, jsonData)
          .then((response) => {
            // Handle the successful POST response here
            if (response.data.message === "Industry updated successfully") {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage(response.data.message);
              // // Cookies.remove("uuid");
              setOpenDrawer(false);
              setIsLoading(false);
              fetchData();
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setIsLoading(false);
              setAlertMessage(response.data.message);
              // setPostError(response.data.message);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            setIsLoading(false);
            console.error("POST Error:", error);
          });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Funtion for change status of single data
  const handleActiveChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
      activeStatus: multistatusValue || singleData.activeStatus === 1 ? 0 : 1,
    };
    axiosPost
      .post(`industrymaster/change/activestatus`, jsonData)
      .then((response) => {
        if (response.data.message === "Active status updated successfully") {
          // setEffectToggle(!effectToggle);
          // setSelectedItems([]);
          // setActionData("");
          setAlertMessage("Updated successfully.");
          setIsAlertVisible(true);
          setAlertSeverity("success");
          handleCloseMenu();
          setIsLoading(false);
          fetchData();
          setSelectedId("");
          setMultistatusValue("");
          setRowSelectionModel("");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for delete single data
  const handleDelete = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
    };
    axiosPost
      .delete(`industrymaster/delete`, { data: jsonData })
      .then((response) => {
        // setEffectToggle(!effectToggle);
        handleCloseMenu();
        setAlertMessage("Deleted successfully.");
        setIsAlertVisible(true);
        setAlertSeverity("success");
        setIsLoading(false);
        setSelectedId("");
        setMultistatusValue("");
        setRowSelectionModel("");
        fetchData();
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "#",
    //   flex: 1,
    //   width: 60,
    //   maxWidth: 80,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "industryName",
      headerName: "Industry",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        const status = params.value; // 'app' or 'rej'
        const style = {
          color:
            status === "Inactive"
              ? "#FFA500"
              : status === "Active"
              ? "#008000"
              : null,
        };

        return <span style={style}>{status}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      // disableColumnMenu: true,
      flex: 1,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<MoreVert />}
          label="Save"
          sx={{ position: "relative", color: "primary.main" }}
          onClick={(event) => handleSaveClick(event, params.row)}
        />
      ),
    },
  ];

  const formatDate = (dateString) => {
    // Create a Date object from the input string
    const date = new Date(dateString);

    // Handle invalid date
    if (isNaN(date.getTime())) {
      return { date: "Invalid Date", time: "" };
    }

    // Extract day, month, and year for the date
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    const year = date.getFullYear();

    // Format date as '26 Jul, 2024'
    const formattedDate = `${day} ${month}, ${year}`;

    // Extract hours and minutes for the time
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    // Format time as '12:45'
    const formattedTime = `${hours}:${minutes}`;

    return { date: formattedDate, time: formattedTime };
  };
  const rows = data?.map((item, index) => {
    const { date: formattedDate, time: formattedTime } = formatDate(
      item.createdDate
    );

    return {
      id: index + 1,
      industryName: capitalizeText(item.industryName),
      createdDate: `${formattedDate} - ${formattedTime}`,
      status:
        item.activeStatus == 0
          ? "Inactive"
          : item.activeStatus == 1
          ? "Active"
          : null,
      fullData: item,
    };
  });

  const title = "Industry";
  const boxSx = {
    "& .super-app-theme--header": {
      backgroundColor: "#0088AB",
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        backgroundColor: "#0088AB",
      },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
      color: "white",
    },
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <CreateButton
          heading={title}
          pagecount={dataCount}
          onAddClick={toggleDrawer(true, 1)}
        />{" "}
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <IconButton onClick={handleRefresh} size="small">
            <RefreshOutlined />
          </IconButton>
          <SearchFilter
            onSearchButtonClick={handleSearchInputChange}
            searchValue={searchValue}
          />
          <FormControl size="small" sx={{ minWidth: "150px" }}>
            <InputLabel sx={{ fontSize: "14px" }} id="game">
              {"Status"}
            </InputLabel>
            <Select
              MenuProps={MenuProps}
              sx={{
                fontSize: "14px",
                height: "34px",
                bgcolor: isStatusSelected ? "#185aa617" : undefined,
              }}
              placeholder={"Status"}
              labelId="status"
              id="status"
              label={"Status"}
              value={statusFilter}
              onChange={(e) => handleStatusType(e.target.value)}
            >
              <MenuItem sx={{ fontSize: "14px" }} value={3}>
                All
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px" }} value={1}>
                Active
              </MenuItem>
              <MenuItem sx={{ fontSize: "14px" }} value={0}>
                Inactive
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box sx={{ overflow: "auto" }}>
        <MasterTable
          rows={rows}
          columns={columns}
          areCheckboxesEnabled={areCheckboxesEnabled}
          title={title}
          limit={limit}
          loading={isLoading}
          boxSx={boxSx}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={dataCount}
          paginationModel={{ pageNumber, limit }}
          handleMultiDelete={handleMultiDelete}
          handleMultiStatus={handleMultiStatus}
          multistatusValue={multistatusValue}
          setRowSelectionModel={setRowSelectionModel}
          rowBtn={rowBtn}
          rowSelectionModel={rowSelectionModel}
          handleSortModelChange={handleSortModelChange}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleDrawer(true, 2)} sx={{ width: 140 }}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleStatusOpen}>
          Mark as {singleData.activeStatus === 0 ? "active" : "inactive"}
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen}>Delete</MenuItem>
      </Menu>

      {/* main drawer  */}
      <CDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        title={title}
        loading={isButtonDisabled}
        buttonName={openDrawerType === 1 ? "Create" : "Save"}
        openType={openDrawerType}
        onSave={handleSubmit}
      >
        {/* Content for CDrawer */}
        <Box sx={{ my: 1 }}>
          <CInput
            label="Industry"
            value={industry}
            name="role"
            onChange={(e) => setIndustry(e.target.value)}
          />
        </Box>
      </CDrawer>
      {/* main drawer end  */}

      <AlertDialog
        onsubmit={handleActiveChange}
        open={openActive}
        handleClose={() => setOpenActive(false)}
        text={`Are you sure want to ${
          singleData.activeStatus == 1 ? "Active" : "Inactive"
        } ?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleActiveChange}
        open={openMulitiStatus}
        handleClose={() => setOpenMultistatus(false)}
        text={`Are you sure want to change status of ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openDel}
        handleClose={() => setOpenDel(false)}
        text={"Are you sure want to delete ?"}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openMulitiDelete}
        handleClose={() => setOpenMultiDelete(false)}
        text={`Are you sure want to delete ${selectedId.length} items?`}
      ></AlertDialog>
      {isAlertVisible && (
        <AutoHideAlert
          severity={alertSeverity}
          message={alertMessage}
          autoHideDuration={3000}
          open={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </>
  );
}
