"use client";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Card, Typography } from "@mui/material";
import AuthLogin from "./AuthLogin";
import AuthReset from "./AuthReset";
import { axiosPost } from "../../../lib/api";
import AutoHideAlert from "../../../Components/Container/AutoHideAlert";
import Cookies from "js-cookie";
const Login = () => {
  const [postError, setPostError] = useState();
  const router = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = Cookies.get("token") !== undefined;
  const ACCESS_TOKEN = Cookies.get("token");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [verifyOTP, setVerifyOTP] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isForgot, setisForgot] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUserName] = useState("");
  const [showOtp, setShowOtp] = useState(false);
  const [showReset, setShowReset] = useState(false);

  const handleSendOtp = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    const jsonStructure = {
      userName: username,
    };
    if (username.trim() === "") {
      setErrorMessage("Please enter a username.");
      setTimeout(() => {
        setErrorMessage("");
      }, 4000);
    } else {
      try {
        axiosPost.post("user/send/otp", jsonStructure).then((response) => {
          if (response.data.message === "Email sent successfully") {
            // router.push('/forgot_password');
            setShowOtp(true);
            setShowReset(false);
          } else {
            setPostError(response.data.message);
            setShowReset(false);
            setShowOtp(false);
          }
        });
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  };

  const onUserChange = (event) => {
    setUserName(event.target.value);
    setErrorMessage("");
  };

  const handleVerifyOTP = () => {
    // e.preventDefault();
    const jsonStructure = {
      // access_token: ACCESS_TOKEN,
      otpNumber: verifyOTP,
      userName: username,
    };
    try {
      axiosPost.post("user/verify/otp", jsonStructure).then((response) => {
        if (response.data.message === "OTP verified successfully") {
          // router.push("/reset-password");
          setShowReset(true);
        } else {
          setPostError(response.data);
          setShowReset(false);
        }
      });
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const [passwordShown, setPasswordShown] = useState(false);

  const toggleButton = () => {
    setPasswordShown(!passwordShown);
  };

  const handleBackLogin = () => {
    router('/login');
  };

  const backgroundImageUrl = "url(/images/bg.png)";

  const handleUpdate = (e) => {
    e.preventDefault();
    const jsonStructure = {
      access_token: ACCESS_TOKEN,
      userName: username,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    try {
      axiosPost
        .post("user/update/password", jsonStructure)
        .then((response) => {
          
          if (response.data.message === "Password updated successfully") {
            router("/login");
          } else {
            setPostError(response.data.message);
            setAlertMessage(response.data.message);
          }
        })
        .catch((error) => {
          console.error("POST Error:", error);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
       <Box
        sx={{
          position: "relative",
          height: { xs: "100vh", md: "100vh" },
          overflow: "hidden",
          background: "#fafafa",
        }}
      >
        <Grid
          container
          spacing={2}
          height={"auto"}
          justifyContent="center"
          sx={{ overflow: "hidden" }}
        >
          {/* <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            // display="flex"
            sx={{ display: { xs: "none", lg: "flex" } }}
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component={"img"}
              textAlign="center"
              sx={{
                width: "100%",
                position: "relative",
              }}
              src="/images/600x400.png"
            ></Box>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={12}
            // lg={6}
            // xl={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100vh",
              padding: "0px 25px !important",
              // background: "white",
            }}
          >
           <Box
               sx={{
                width: 340,
              }}
              bgcolor={'white'}
              boxShadow={1}
              borderRadius={6}
              p={4}
              position={"relative"}
            >
              <Box display={"flex"} width={"100%"} justifyContent={"center"} pb={1}>
                <img
                  src="images/logo.png"
                  alt="logo"
                  style={{ height: "32px" }}
                  priority
                />
              </Box>

              {/* <Box my={2}>
                <LogoLite />
              </Box> */}
              <Typography
                variant="h5"
                sx={{ fontWeight: "500", marginBottom: "5px" }}
              >
                Verify your Account
              </Typography>

              <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                {!showReset
                  ? "Enter the OTP send to the user email"
                  : "Reset your Password"}
              </Typography>
              {!showReset ? (
                <>
                  <AuthLogin
                    verifyOnOTP={handleVerifyOTP}
                    verifyOTP={verifyOTP}
                    sendOnOtp={handleSendOtp}
                    setVerifyOTP={setVerifyOTP}
                    postError={postError}
                    onUserChange={onUserChange}
                    errorMsg={errorMessage}
                    show={showOtp}
                    isButtonDisabled={isButtonDisabled}
                    handleBackLogin={handleBackLogin}
                  />
                </>
              ) : (
                <>
                  <AuthReset
                    onUpdate={handleUpdate}
                    passwordShown={passwordShown}
                    toggleButton={toggleButton}
                    newPassword={newPassword}
                    setNewPassword={setNewPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                    postError={postError}
                  />
                </>
              )}
            </Box>
            {isAlertVisible && (
              <AutoHideAlert
                severity={alertSeverity}
                message={alertMessage}
                autoHideDuration={3000}
                open={isAlertVisible}
                onClose={() => setIsAlertVisible(false)}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
