import React, { useState, useEffect, useRef } from "react";
import MasterTable from "../../Components/MasterTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  Add,
  CloseOutlined,
  MoreVert,
  Close,
  ArrowBack,
  RefreshOutlined,
} from "@mui/icons-material";
import CDrawer from "../../Components/Container/CusDrawer";
import { axiosGet, axiosPost, axiosPostForm } from "../../lib/api";
import GridContainer from "../../Components/Container/GridContainer";
import Cookies from "js-cookie";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  Select,
  Chip,
  IconButton,
  TextField,
  capitalize,
  Drawer,
  Typography,
  Collapse,
  Badge,
} from "@mui/material";
import CreateButton from "../../Components/buttons/CreateButton";
import CInput from "../../Components/Container/CInput";
import AutoHideAlert from "../../Components/Container/AutoHideAlert";
import AlertDialog from "../../Components/Container/AlertDialog";
import CAlertDialog from "../../Components/Container/CAlertDialog";
import SearchFilter from "../../Components/buttons/SearchFilter";
import FilterButton from "../../Components/buttons/FilterButton";
import { capitalizeText } from "../../lib/config";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 160,
    },
  },
};

export default function Exhibitors() {
  const ACCESS_TOKEN = Cookies.get("token");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [sortField, setSortField] = useState("createdDate");
  const [sortOrder, setSortOrder] = useState("descending");
  const [limit, setLimit] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataCount, setdataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerType, setOpenDrawerType] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filtersList, setfiltersList] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [isAOESelected, setIsAOESelected] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [AOEFilter, setAOEFilter] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [urlError, setUrlError] = useState("");
  const [rowBtn, setRowBtn] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [multistatusValue, setMultistatusValue] = useState("");
  const [openMulitiStatus, setOpenMultistatus] = useState(false);
  const [openMulitiDelete, setOpenMultiDelete] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [country, setCountry] = useState("India");
  const [organization, setOrganization] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [picture, setPicture] = useState(null);
  const [pictureUrl, setPictureUrl] = useState('');
  const [pictureName, setPictureName] = useState("");
  const fileInputRef = useRef(null);
  const open = Boolean(anchorEl);
  const areCheckboxesEnabled = true;

  const [AOEData, setAOEData] = useState([]);
  const [AOEFData, setAOEFData] = useState([]);
  const [AOE, setAOE] = useState([]);

  // Function to get selected row data
  const getSelectedRowsData = () => {
    return rows.filter((row) => rowSelectionModel.includes(row.id));
  };

  useEffect(() => {
    const selectedRowsData = getSelectedRowsData();
    const selectedDatauniqids = selectedRowsData.map((row) => row.fullData);
    const cusID = selectedDatauniqids.map((row) => row.dataUniqId);
    setSelectedId(cusID);
    if (cusID.length > 0) {
      setRowBtn(true);
    } else {
      setRowBtn(false);
    }
    // let cusId=selectedDatauniqids.filter((data)=>data._id)
  }, [rowSelectionModel]);

  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      const { field, sort } = model[0];
      setSortField(field);
      setSortOrder(sort == "asc" ? "ascending" : "descending");
    } else {
      // Reset to default sorting if needed
      setSortField("createdDate");
      setSortOrder("descending");
    }
  };

  const maxSizeInMB = 2;
  const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
  const [sizeError, setSizeError] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > maxSizeInBytes) {
        setSizeError(
          `File size exceeds ${maxSizeInMB} MB. Please select a smaller file.`
        );
        setIsButtonDisabled(true)
        return;
      }
      setIsButtonDisabled(false)

      setSizeError("");
      setPicture(file);
      setPictureUrl(URL.createObjectURL(file));
      setPictureName(file.name);
    }
  };

  const resetFileInput = () => {
    setPicture(null);
    setPictureUrl('');
    setPictureName("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validateURL = (url) => {
    const urlRegex =
      /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
    return urlRegex.test(url);
  };

  const handleURLChange = (e) => {
    const value = e.target.value;
    setEmail(value); // Assuming setEmail sets the URL here, you may rename it to setURL for clarity.

    if (value === "") {
      setUrlError(""); // Clear error if input is empty
    } else if (!validateURL(value)) {
      setUrlError("Invalid URL format");
    } else {
      setUrlError("");
    }
  };

  // Function to update searchValue when input changes
  const handleSearchInputChange = (input) => {
    setPageNumber(1);
    setSearchValue(input);
  };

  const handlefilterBadgeVisible = () => {
    if (isStatusSelected || isAOESelected) {
      // setFilterBadgeVisible(true);
      return true;
    } else {
      // setFilterBadgeVisible(false);
      return false;
    }
  };

  // Toggel filter component
  const HandleChangeFilter = () => {
    setfiltersList(!filtersList);
  };

  const handleRefresh = () => {
    setSearchValue("");
    setIsStatusSelected(false);
    setIsAOESelected(false);
    setStatusFilter("");
    setAOEFilter("");
    fetchData();
  };

  // Toggel Drawer
  const toggleDrawer = (newOpen, type) => () => {
    // type 1 : Create
    // type 2 : Edit
    setOpenDrawer(newOpen);
    setOpenDrawerType(type);
    handleCloseMenu();
    if (type === 1) {
      setFirstName("");

      setEmail("");
      setMobileNo("");
      setCountry("");
      setAOE("4");
      setPicture(null);
      setPictureUrl("");
      setOrganization("");
      setJobTitle("");
    } else {
      setFirstName(singleData.title);
      setEmail(singleData.webUrl);
      setPicture(singleData.media);
      setPictureUrl(singleData.media);
    }
  };

  const handleSaveClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    const fullData = item.fullData;
    setSingleData(fullData);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Open Status change warning box
  const handleStatusOpen = () => {
    setOpenActive(true);
    handleCloseMenu();
  };

  // Open Status change warning box
  const handleViewOpen = () => {
    setOpenViewDrawer(true);
    handleCloseMenu();
  };

  const handleDeleteOpen = () => {
    setOpenDel(true);
    handleCloseMenu();
  };
  const handleMultiDelete = () => {
    setOpenMultiDelete(true);
  };
  // open multi status waring
  const handleMultiStatus = (event) => {
    setOpenMultistatus(true);
    setMultistatusValue(event.target.value);
  };

  // Handler to log the page number on page change
  const handlePageChange = (model, newPage) => {
    let page = model.page + 1;
    let pageSize = model.pageSize;
    setPageNumber(page);
    setLimit(pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setLimit(newPageSize);
    setPageNumber(0);
  };

  // Filter Functions Start
  const handleStatusType = (value) => {
    setPageNumber(1);
    setStatusFilter(value);
    setIsStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };
  const handleAOEType = (value) => {
    setPageNumber(1);
    setAOEFilter(value);
    setIsAOESelected(true);
    // handlefilterBadgeVisible(true)
  };
  // Filter Functions End

  const fetchAOE = async () => {
    axiosGet
      .get(`expertisemaster/get/admin/?userToken=${ACCESS_TOKEN}`)
      // &activeStatus=${1}
      .then((response) => {
        const data = response.data.data;
        setAOEFData(data);
        const filteredData = data.filter((item) => item.activeStatus === 1);
        setAOEData(filteredData);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchAOE();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    axiosGet
      .get(
        `exhibitorsection/get/admin?userToken=${ACCESS_TOKEN}&page=${pageNumber}&limit=${limit}&isAll=${isAll}&sortField=${sortField}&sortOrder=${sortOrder}&query=${searchValue}&activeStatus=${
          statusFilter == 3 ? "" : statusFilter
        }`
        // AOEFilter
      )
      // &activeStatus=${statusFilter == 3 ?"":statusFilter}
      .then((response) => {
        if (response.data.message === "Exhibitors retrieved successfully") {
          setData(response.data.data);
          setdataCount(response.data.totalItems);
          setPageNumber(pageNumber === 0 ? 1 : pageNumber);
          setPageCount(response.data.total_pages);
          setIsLoading(false);
          // setIsAlertVisible(true);
          // setAlertSeverity("success");
          // setAlertMessage("response.data.error");
        } else {
          setIsLoading(false);
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setAlertMessage("Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setIsLoading(true);
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    pageNumber,
    limit,
    searchValue,
    statusFilter,
    AOEFilter,
    sortOrder,
    sortField,
  ]);

  // Funtion for create new data or edit existing data
  const handleSubmit = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setIsLoading(true);

    const formData = new FormData();
    formData.append("userToken", ACCESS_TOKEN);
    formData.append("title", firstName);
    formData.append("webUrl", email);

    if (openDrawerType == 1) {
      formData.append("media", picture);
    } else if (openDrawerType == 2) {
      formData.append("dataUniqId", singleData?.dataUniqId);
      const photoValue =
        picture === singleData.media ? singleData.existingPhotoPath : picture;

      if (typeof photoValue === "string") {
        formData.append("existingPhotoPath", photoValue);
      } else {
        formData.append("media", photoValue);
      }
    }
    try {
      if (openDrawerType == 1) {
        axiosPostForm
          .post(`exhibitorsection/create`, formData)
          .then((response) => {
            // Handle the successful POST response here
            // if (response.data.action === "success") {
            // If response data action is 200, show the alert
            setIsAlertVisible(true);
            setAlertSeverity("success");
            setAlertMessage(response.data.message);
            // Cookies.remove("uuid");
            setOpenDrawer(false);
            setIsLoading(false);
            fetchData();
            // } else {
            //   setIsAlertVisible(true);
            //   setAlertSeverity("error");
            //   setIsLoading(false);
            //   setAlertMessage(response.data.message);
            // }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(error?.response.data.error);
          });
      } else {
        axiosPostForm
          .put(`exhibitorsection/update`, formData)
          .then((response) => {
            // Handle the successful POST response here
            if (
              response.data.message === "Exhibitor Section updated successfully"
            ) {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage(response.data.message);
              // Cookies.remove("uuid");
              setOpenDrawer(false);
              setIsLoading(false);
              fetchData();
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setIsLoading(false);
              setAlertMessage(response.error);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setIsLoading(false);
            setAlertMessage(error?.response.data.error);
          });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Funtion for change status of single data
  const handleActiveChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      // dataUniqIds: [singleData.dataUniqId],
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
      activeStatus: multistatusValue || singleData.activeStatus === 1 ? 0 : 1,
    };
    axiosPost
      .post(`exhibitorsection/change/activestatus`, jsonData)
      .then((response) => {
        if (response.data.message === "Active status updated successfully") {
          // setEffectToggle(!effectToggle);
          // setSelectedItems([]);
          // setActionData("");
          setAlertMessage("Updated successfully.");
          setIsAlertVisible(true);
          setAlertSeverity("success");
          handleCloseMenu();
          setIsLoading(false);
          fetchData();
          setSelectedId("");
          setMultistatusValue("");
          setRowSelectionModel("");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for delete single data
  const handleDelete = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
    };
    axiosPost
      .delete(`exhibitorsection/delete`, { data: jsonData })
      .then((response) => {
        // if (response.data.message === "Attendees deleted successfully") {
        // setEffectToggle(!effectToggle);
        handleCloseMenu();
        setAlertMessage("Deleted successfully.");
        setIsAlertVisible(true);
        setAlertSeverity("success");
        setIsLoading(false);
        fetchData();
        setSelectedId("");
        setMultistatusValue("");
        setRowSelectionModel("");
        // }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "#",
    //   flex: 1,
    //   width: 60,
    //   maxWidth: 80,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "title",
      headerName: "First Name",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "webUrl",
      headerName: "Last Name",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 200,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        const status = params.value; // 'app' or 'rej'
        const style = {
          color:
            status === "Inactive"
              ? "#FFA500"
              : status === "Active"
              ? "#008000"
              : null,
        };

        return <span style={style}>{status}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      // disableColumnMenu: true,
      flex: 1,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<MoreVert />}
          label="Save"
          sx={{ position: "relative", color: "primary.main" }}
          onClick={(event) => handleSaveClick(event, params.row)}
        />
      ),
    },
  ];

  const rows = data?.map((item, index) => ({
    id: index + 1,
    title: capitalizeText(item.title),
    webUrl: capitalizeText(item.webUrl),
    status:
      item.activeStatus === 0
        ? "Inactive"
        : item.activeStatus === 1
        ? "Active"
        : null,
    fullData: item,
  }));

  const title = "Exhibitors";
  const boxSx = {
    "& .super-app-theme--header": {
      backgroundColor: "#0088AB",
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        backgroundColor: "#0088AB",
      },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
      color: "white",
    },
  };

  const FilterComponent = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 1,
        }}
      >
        <IconButton onClick={handleRefresh} size="small">
          <RefreshOutlined />
        </IconButton>

        <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Status"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isStatusSelected ? "#185aa617" : undefined,
            }}
            placeholder={"Status"}
            labelId="status"
            id="status"
            label={"Status"}
            value={statusFilter}
            onChange={(e) => handleStatusType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              Active
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={0}>
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <CreateButton
          heading={title}
          pagecount={dataCount}
          onAddClick={toggleDrawer(true, 1)}
        />
        <Box display={"flex"} alignItems={"center"} gap={1.5}>
          {" "}
          <SearchFilter
            onSearchButtonClick={handleSearchInputChange}
            searchValue={searchValue}
          />
          <Badge
            color="secondary"
            variant="dot"
            invisible={!handlefilterBadgeVisible()}
          >
            <FilterButton
              HandleChangeFilter={HandleChangeFilter}
              filtersList={filtersList}
            />
          </Badge>
        </Box>
      </Box>

      <Collapse in={filtersList} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "end", py: 0.5 }}>
          {FilterComponent()}
        </Box>
      </Collapse>
      <Box sx={{ overflow: "auto" }}>
        <MasterTable
          rows={rows}
          columns={columns}
          areCheckboxesEnabled={areCheckboxesEnabled}
          title={title}
          limit={limit}
          loading={isLoading}
          boxSx={boxSx}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={dataCount}
          paginationModel={{ pageNumber, limit }}
          handleMultiDelete={handleMultiDelete}
          handleMultiStatus={handleMultiStatus}
          multistatusValue={multistatusValue}
          setRowSelectionModel={setRowSelectionModel}
          rowBtn={rowBtn}
          rowSelectionModel={rowSelectionModel}
          handleSortModelChange={handleSortModelChange}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleDrawer(true, 2)} sx={{ width: 140 }}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleViewOpen}>View</MenuItem>
        <MenuItem onClick={handleStatusOpen}>
          Mark as {singleData.activeStatus === 0 ? "active" : "inactive"}
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen}>Delete</MenuItem>
      </Menu>

      {/* main drawer  */}
      <CDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        title={title}
        loading={isButtonDisabled}
        buttonName={openDrawerType === 1 ? "Create" : "Save"}
        openType={openDrawerType}
        onSave={handleSubmit}
      >
        {/* Content for CDrawer */}
        <GridContainer spacing={2}>
          <Grid item xs={12} display={"flex"} gap={3} justifyContent={"center"}>
            {/* <Typography variant="button" color="initial">
              Choose Profile Picture
            </Typography> */}
            <Stack>
              {pictureUrl ? (
                <div
                  style={{
                    aspectRatio: "1/1",
                    width: "170px",
                    height: "120px",
                    border: "2px dashed #ccc",
                    borderRadius: "10px",
                    padding: "4px",
                    textAlign: "center",
                    position: "relative",
                    display: "grid",
                    placeItems: "center",
                    overflow: "hidden",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                    src={pictureUrl}
                    alt={pictureName}
                  />
                  <IconButton
                    sx={{
                      padding: 0.5,
                      px: 1,
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      background: "#f44336",
                    }}
                    onClick={resetFileInput}
                  >
                    <CloseOutlined
                      sx={{ color: "#fff", width: 16, aspectRatio: 1 / 1 }}
                    />
                  </IconButton>
                </div>
              ) : (
                <Box sx={{display:'flex', flexDirection:'column',alignItems:'center'}}>
                  <label htmlFor="image-upload">
                  <div
                        style={{
                          // aspectRatio: "4/3",
                          width: "170px",
                          height: "120px",
                          // height: "146px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "2px dashed #ccc",
                          borderRadius: "10px",
                          padding: "8px",
                          textAlign: "center",
                        }}
                      >
                      <Box
                        component="img"
                        sx={{
                          height: "110px",
                          width: 110,
                        }}
                        alt="Placeholder"
                        src="/images/upload1280.svg"
                      />
                    </div>
                    <input
                      id="image-upload"
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </label>
                  {sizeError ? (
                    <Typography variant="caption" color="red">
                      {sizeError}
                    </Typography>
                  ) : (
                    <Typography variant="caption" color="#4b5563">
                      Maximum image size is 2MB.
                    </Typography>
                  )}
                </Box>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} display={"flex"} gap={3}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
              width={"100%"}
            >
              <CInput
                label="Title"
                value={firstName}
                name="title"
                onChange={(e) => setFirstName(e.target.value)}
              />

              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={0.5}
                width={"100%"}
              >
                <Box>
                  <CInput
                    label="Web Url"
                    value={email}
                    name="webUrl"
                    onChange={handleURLChange}
                  />
                  {urlError && (
                    <Typography
                      sx={{ fontSize: "12px", color: "#FF0000", px: 0.5 }}
                    >
                      {urlError}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
        </GridContainer>
      </CDrawer>
      {/* main drawer end  */}

      {/* view drawer  */}
      <Drawer
        anchor={"right"}
        open={openViewDrawer}
        onClose={() => setOpenViewDrawer(false)}
        title={title}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            py: 1,
            borderBottom: "1px solid #3636363b",
          }}
        >
          <IconButton onClick={() => setOpenViewDrawer(false)} size="small">
            <ArrowBack style={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h6"
            className="nunito_font"
            style={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#185AA6",
            }}
          >
            View {title}
          </Typography>
        </Box>
        <Box sx={{ width: 400, p: 2 }}>
          <GridContainer>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Title</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.title}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Web Url</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.webUrl}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"} pb={1}>
              <Typography sx={{ color: "gray" }}>Logo</Typography>
              <img
                src={singleData?.media}
                style={{ objectFit: "cover", borderRadius: "10px" }}
                alt="Picture of the author"
              />
              {/* <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData?.media}
              </Typography> */}
            </Grid>
          </GridContainer>
        </Box>
      </Drawer>
      {/* view drawer end  */}

      <AlertDialog
        onsubmit={handleActiveChange}
        open={openActive}
        handleClose={() => setOpenActive(false)}
        text={`Are you sure want to ${
          singleData.activeStatus !== 1 ? "active" : "inactive"
        } ?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleActiveChange}
        open={openMulitiStatus}
        handleClose={() => setOpenMultistatus(false)}
        text={`Are you sure want to ${
          singleData.activeStatus !== 1 ? "active" : "inactive"
        }  ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openDel}
        handleClose={() => setOpenDel(false)}
        text={"Are you sure want to delete ?"}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openMulitiDelete}
        handleClose={() => setOpenMultiDelete(false)}
        text={`Are you sure want to delete ${selectedId.length} items?`}
      ></AlertDialog>
      {isAlertVisible && (
        <AutoHideAlert
          severity={alertSeverity}
          message={alertMessage}
          autoHideDuration={3000}
          open={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </>
  );
}
