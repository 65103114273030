import React, { useState, useEffect, useRef } from "react";
import MasterTable from "../Components/MasterTable";
import { GridActionsCellItem } from "@mui/x-data-grid";
import {
  MoreVert,
  ArrowBack,
  LockOpen,
  Lock,
  RefreshOutlined,
} from "@mui/icons-material";
import CDrawer from "../Components/Container/CusDrawer";
import { axiosGet, axiosPost } from "../lib/api";
import GridContainer from "../Components/Container/GridContainer";
import Cookies from "js-cookie";
import {
  Box,
  Menu,
  MenuItem,
  Grid,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Drawer,
  Typography,
  Badge,
  Collapse,
} from "@mui/material";
import CreateButton from "../Components/buttons/CreateButton";
import CInput from "../Components/Container/CInput";
import AutoHideAlert from "../Components/Container/AutoHideAlert";
import AlertDialog from "../Components/Container/AlertDialog";
import SearchFilter from "../Components/buttons/SearchFilter";
import FilterButton from "../Components/buttons/FilterButton";
import { capitalizeText } from "../lib/config";

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 160,
    },
  },
};

export default function User() {
  const ACCESS_TOKEN = Cookies.get("token");
  const tokenUser = Cookies.get("userName");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openViewDrawer, setOpenViewDrawer] = useState(false);
  const [openActive, setOpenActive] = useState(false);
  const [openDel, setOpenDel] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [data, setData] = useState([]);
  const [isAll, setIsAll] = useState(false);
  const [sortField, setSortField] = useState("createdDate");
  const [sortOrder, setSortOrder] = useState("descending");
  const [limit, setLimit] = useState(15);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataCount, setdataCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openDrawerType, setOpenDrawerType] = useState(1);
  const [pageCount, setPageCount] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [singleData, setSingleData] = useState([]);
  const [fName, setFName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [postError, setPostError] = useState([]);
  const open = Boolean(anchorEl);
  const areCheckboxesEnabled = true;

  const [passwordShown, setPasswordShown] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [filtersList, setfiltersList] = useState(false);
  const [isStatusSelected, setIsStatusSelected] = useState(false);
  const [statusFilter, setStatusFilter] = useState("");
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [rowBtn, setRowBtn] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [multistatusValue, setMultistatusValue] = useState("");
  const [openMulitiStatus, setOpenMultistatus] = useState(false);
  const [openMulitiDelete, setOpenMultiDelete] = useState(false);

  // Function to get selected row data
  const getSelectedRowsData = () => {
    return rows.filter((row) => rowSelectionModel.includes(row.id));
  };

  useEffect(() => {
    const selectedRowsData = getSelectedRowsData();
    const selectedDatauniqids = selectedRowsData.map((row) => row.fullData);
    const cusID = selectedDatauniqids.map((row) => row.dataUniqId);
    setSelectedId(cusID);
    if (cusID.length > 0) {
      setRowBtn(true);
    } else {
      setRowBtn(false);
    }
    // let cusId=selectedDatauniqids.filter((data)=>data._id)
  }, [rowSelectionModel]);


  const handleSortModelChange = (model) => {
    if (model.length > 0) {
      const { field, sort } = model[0];
      setSortField(field);
      setSortOrder(sort == "asc" ? "ascending" : "descending");
    } else {
      // Reset to default sorting if needed
      setSortField("createdDate");
      setSortOrder("descending");
    }
  };

  const toggleButton = () => {
    setPasswordShown(!passwordShown);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (value === "") {
      setEmailError(""); // Clear error if input is empty
    } else if (!validateEmail(value)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }
  };

  // const handleMobileChange = (e) => {
  //   const value = e.target.value;
  //   // if (/^\d*$/.test(value)) {
  //   if (/^\d{0,10}$/.test(value)) {
  //     setMobile(value);
  //     if (value.length === 10) {
  //       setMobileError("");
  //       // }
  //     } else if (value.length > 4) {
  //       setMobileError("Invalid mobile number");
  //     }
  //   }
  // };
  const handleMobileChange = (e) => {
    const value = e.target.value;
    // if (/^\d*$/.test(value)) {
      if (/^\+?\d*$/.test(value)) {
        setMobile(value);
      const digitCount = (value.match(/\d/g) || []).length;
      if (digitCount >= 10 || digitCount <= 5) {
        setMobileError("");
        // }
      } else if (digitCount > 4) {
        setMobileError("Invalid mobile number");
      }
    }
  };

  // Function to update searchValue when input changes
  const handleSearchInputChange = (input) => {
    setPageNumber(1);
    setSearchValue(input);
  };
  const handlefilterBadgeVisible = () => {
    // if (isAppStatusSelected || isDateSelected || isRegSelected || isGameSelected || isTeamSelected || isApprovalSelected) {
    //   // setFilterBadgeVisible(true);
    //   return true;
    // } else {
    //   // setFilterBadgeVisible(false);
    //   return false;
    // }
  };

  // Toggel filter component
  const HandleChangeFilter = () => {
    setfiltersList(!filtersList);
    // if (filtersList === true) {
    //   setIsIndusSelected(false);
    //   setIsRoleSelected(false);
    // }
  };
  const handleRefresh = () => {
    setSearchValue("");
    setIsStatusSelected(false);
    setStatusFilter("");
  };

  const handleStatusType = (value) => {
    setPageNumber(1);
    setStatusFilter(value);
    setIsStatusSelected(true);
    // handlefilterBadgeVisible(true)
  };

  // Filter Functions End

  // Toggel Drawer
  const toggleDrawer = (newOpen, type) => () => {
    // type 1 : Create
    // type 2 : Edit
    setOpenDrawer(newOpen);
    setOpenDrawerType(type);
    handleCloseMenu();

    if (type === 1) {
      setFName("");
      setUserName("");
      setMobile("");
      setEmail("");
      setPassword("");
    } else {
      setFName(singleData.firstName);
      setUserName(singleData.userName);
      setMobile(singleData.mobile);
      setEmail(singleData.email);
      setPassword(singleData.showPassword);
    }
  };

  const handleSaveClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    const fullData = item.fullData;
    setSingleData(fullData);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // Open Status change warning box
  const handleStatusOpen = () => {
    setOpenActive(true);
    handleCloseMenu();
  };

  // Open Status change warning box
  const handleViewOpen = () => {
    setOpenViewDrawer(true);
    handleCloseMenu();
  };

  const handleDeleteOpen = () => {
    setOpenDel(true);
    handleCloseMenu();
  };

  const handleMultiDelete = () => {
    setOpenMultiDelete(true);
  };
  // open multi status waring
  const handleMultiStatus = (event) => {
    setOpenMultistatus(true);
    setMultistatusValue(event.target.value);
  };

  // Handler to log the page number on page change
  const handlePageChange = (model, newPage) => {
    let page = model.page + 1;
    let pageSize = model.pageSize;
    setPageNumber(page);
    setLimit(pageSize);
  };

  const handlePageSizeChange = (newPageSize) => {
    setLimit(newPageSize);
    setPageNumber(0);
  };
  const fetchData = async () => {
    setIsLoading(true);
    axiosGet
      .get(
        `usermaster/get/users?userToken=${ACCESS_TOKEN}&page=${pageNumber}&limit=${limit}&isAll=${isAll}&query=${searchValue}&sortField=${sortField}&sortOrder=${sortOrder}&activeStatus=${
          statusFilter === 3 ? "" : statusFilter
        }`
      )
      .then((response) => {
        if (response.data.message === "Users retrieved successfully") {
          setData(response.data.data);
          setdataCount(response.data.totalItems);
          setPageNumber(pageNumber === 0 ? 1 : pageNumber);
          setPageCount(response.data.total_pages);
          setIsLoading(false);
          // setIsAlertVisible(true);
          // setAlertSeverity("success");
          // setAlertMessage("response.data.error");
        } else {
          setPostError(response.data.error);
          setIsAlertVisible(true);
          setAlertSeverity("error");
          setAlertMessage("Something went wrong.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, limit, ACCESS_TOKEN, statusFilter,sortField,sortOrder,statusFilter,searchValue]);

  // Funtion for create new data or edit existing data
  const handleSubmit = () => {
    setIsButtonDisabled(true);
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqId: singleData?.dataUniqId,
      // userType: userType,
      userType: 1,
      userName: userName,
      showPassword: password,
      firstName: fName,
      email: email,
      mobile: mobile,
      modifiedBy: tokenUser,
      createdBy: tokenUser,
      // tokenUser
    };
    try {
      if (openDrawerType == 1) {
        axiosPost
          .post(`usermaster/create/user`, jsonData)
          .then((response) => {
            // Handle the successful POST response here
            if (response.data.message === "User created successfully") {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage(response.data.message);
              // Cookies.remove("uuid");
              setOpenDrawer(false);
              setIsLoading(false);
              fetchData();
            } else {
              setIsAlertVisible(true);
              setAlertSeverity("error");
              setAlertMessage(response.data.message);
              setPostError(response.data.message);
              console.error("API Error:", response.data.message);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setAlertMessage(error.response.data.error);
          });
      } else {
        axiosPost
          .put(`usermaster/update/user`, jsonData)
          .then((response) => {
            // Handle the successful POST response here
            if (response.data.message === "User updated successfully") {
              // If response data action is 200, show the alert
              setIsAlertVisible(true);
              setAlertSeverity("success");
              setAlertMessage(response.data.message);
              // Cookies.remove("uuid");
              setOpenDrawer(false);
              fetchData();
            } else {
              // setIsAlertVisible(true);
              // setAlertSeverity("error");
              // setIsLoading(false);
              // setAlertMessage(response.data.message);
              setPostError(response.data.message);
            }
          })
          .catch((error) => {
            // Handle POST errors here
            console.error("POST Error:", error);
            setIsAlertVisible(true);
            setAlertSeverity("error");
            setAlertMessage(error.response.data.error);
          });
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  // Funtion for delete single data
  const handleDelete = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
    };
    axiosPost
      .delete(`usermaster/delete`, { data: jsonData })
      .then((response) => {
        if (response.data.message === "Users deleted successfully") {
          // Users deleted successfully
          // setEffectToggle(!effectToggle);
          handleCloseMenu();
          setAlertMessage("Deleted successfully.");
          setIsAlertVisible(true);
          setAlertSeverity("success");
          setIsLoading(false);
          fetchData();
          setSelectedId("");
          setMultistatusValue("");
          setRowSelectionModel("");
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  // Funtion for change status of single data
  const handleActiveChange = () => {
    setIsLoading(true);
    const jsonData = {
      userToken: ACCESS_TOKEN,
      dataUniqIds: selectedId.length > 0 ? selectedId : [singleData.dataUniqId],
      activeStatus: multistatusValue || singleData.activeStatus === 1 ? 0 : 1,
    };
    axiosPost
      .post(`usermaster/change/activestatus`, jsonData)
      .then((response) => {
        // setEffectToggle(!effectToggle);
        // setSelectedItems([]);
        // setActionData("");
        setAlertMessage("Updated successfully.");
        setIsAlertVisible(true);
        setAlertSeverity("success");
        handleCloseMenu();
        setIsLoading(false);
        fetchData();
        setSelectedId("");
        setMultistatusValue("");
        setRowSelectionModel("");
      })
      .catch((error) => {
        // Handle errors here
        console.error("Error:", error);
      });
  };

  const columns = [
    // {
    //   field: "id",
    //   headerName: "#",
    //   flex: 1,
    //   width: 60,
    //   maxWidth: 80,
    //   disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    {
      field: "firstName",
      headerName: "Name",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      minWidth: 100,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 100,
      maxWidth: 200,
      // disableColumnMenu: true,
      headerClassName: "super-app-theme--header",
    },
    // {
    //   field: "userTimeZone",
    //   headerName: "User Time Zone",
    //   flex: 1,
    //   maxWidth: 200,
    //   minWidth: 100,
    //   // disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    // },
    // {
    //   field: "userType",
    //   headerName: "User Type",
    //   flex: 1,
    //   maxWidth: 200,
    //   minWidth: 100,
    //   disableColumnMenu: true,
    //   headerClassName: "super-app-theme--header",
    //   renderCell: (params) => {
    //     const status = params.value;
    //     const style = {
    //       color:
    //         status === "Super Admin"
    //           ? "#FFA500"
    //           : status === "Admin"
    //           ? "#008000"
    //           : status === "Guest"
    //           ? "#FF0000"
    //           : status === "User"
    //           ? "#FF0000"
    //           : null,
    //     };

    //     return <span style={style}>{status}</span>;
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      maxWidth: 200,
      minWidth: 100,
      // disableColumnMenu: true,
    headerClassName: "super-app-theme--header",
      sortable: false,
      renderCell: (params) => {
        const status = params.value; // 'app' or 'rej'
        const style = {
          color:
            status === "Inactive"
              ? "#FFA500"
              : status === "Active"
              ? "#008000"
              : null,
        };

        return <span style={style}>{status}</span>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      // disableColumnMenu: true,
      flex: 1,
      maxWidth: 100,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <GridActionsCellItem
          icon={<MoreVert />}
          label="Save"
          sx={{ position: "relative", color: "primary.main" }}
          onClick={(event) => handleSaveClick(event, params.row)}
        />
      ),
    },
  ];

  const rows = data?.map((item, index) => ({
    id: index + 1,
    firstName: capitalizeText(item.firstName),
    userName: item.userName,
    mobile: item.mobile,
    email: item.email,
    // userTimeZone: item.userTimeZone,
    // userType:
    //   item.userType == 1
    //     ? "Super Admin"
    //     : item.status == 2
    //     ? "Admin"
    //     : item.status == 3
    //     ? "Guest"
    //     : item.status == 4
    //     ? "User"
    //     : null,
    status:
      item.activeStatus == 0
        ? "Inactive"
        : item.activeStatus == 1
        ? "Active"
        : null,
    fullData: item,
  }));

  const title = "User Management";
  const boxSx = {
    "& .super-app-theme--header": {
      backgroundColor: "#0088AB",
      color: "white",
      fontSize: "16px",
      fontWeight: "bold",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer":
      {
        backgroundColor: "#0088AB",
      },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root": {
      color: "white",
    },
    "& .MuiDataGrid-columnHeaderCheckbox .MuiCheckbox-root.Mui-checked": {
      color: "white",
    },
  };
  const FilterComponent = () => {
    return (
      <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 1,
      }}
    >
      <IconButton onClick={handleRefresh} size="small">
        <RefreshOutlined />
      </IconButton>
        <FormControl size="small" sx={{ minWidth: "150px" }}>
          <InputLabel sx={{ fontSize: "14px" }} id="game">
            {"Status"}
          </InputLabel>
          <Select
            MenuProps={MenuProps}
            sx={{
              fontSize: "14px",
              height: "34px",
              bgcolor: isStatusSelected ? "#185aa617" : undefined,
            }}
            placeholder={"Status"}
            labelId="status"
            id="status"
            label={"Status"}
            value={statusFilter}
            onChange={(e) => handleStatusType(e.target.value)}
          >
            <MenuItem sx={{ fontSize: "14px" }} value={3}>
              All
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={1}>
              Active
            </MenuItem>
            <MenuItem sx={{ fontSize: "14px" }} value={0}>
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <>
      {" "}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        flexDirection={{ xs: "column", md: "row" }}
      >
        <CreateButton
          heading={title}
          pagecount={dataCount}
          onAddClick={toggleDrawer(true, 1)}
        />
        <Box display={"flex"} alignItems={"center"} gap={1.5}>
          {" "}
          <SearchFilter
            onSearchButtonClick={handleSearchInputChange}
            searchValue={searchValue}
          />
          <Badge
            color="secondary"
            variant="dot"
            invisible={!handlefilterBadgeVisible()}
          >
            <FilterButton
              HandleChangeFilter={HandleChangeFilter}
              filtersList={filtersList}
            />
          </Badge>
        </Box>
      </Box>
      <Collapse in={filtersList} timeout="auto" unmountOnExit>
        <Box sx={{ display: "flex", justifyContent: "end", py: 0.5 }}>
          {FilterComponent()}
        </Box>
      </Collapse>
      <Box sx={{ overflow: "auto" }}>
       <MasterTable
          rows={rows}
          columns={columns}
areCheckboxesEnabled={areCheckboxesEnabled}
          title={title}
          limit={limit}
          loading={isLoading}
          boxSx={boxSx}
          handlePageChange={handlePageChange}
          handlePageSizeChange={handlePageSizeChange}
          rowCount={dataCount}
          paginationModel={{ pageNumber, limit }}
          handleMultiDelete={handleMultiDelete}
          handleMultiStatus={handleMultiStatus}
          multistatusValue={multistatusValue}
          setRowSelectionModel={setRowSelectionModel}
          rowBtn={rowBtn}
          rowSelectionModel={rowSelectionModel}
          handleSortModelChange={handleSortModelChange}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={toggleDrawer(true, 2)} sx={{ width: 140 }}>
          Edit
        </MenuItem>
        <MenuItem onClick={handleViewOpen}>View</MenuItem>
        <MenuItem onClick={handleStatusOpen}>
          Mark as {singleData.activeStatus === 0 ? "active" : "inactive"}
        </MenuItem>
        <MenuItem onClick={handleDeleteOpen}>Delete</MenuItem>
      </Menu>
      {/* main drawer  */}
      <CDrawer
        open={openDrawer}
        close={() => setOpenDrawer(false)}
        title={title}
        loading={isButtonDisabled}
        buttonName={openDrawerType === 1 ? "Create" : "Save"}
        openType={openDrawerType}
        onSave={handleSubmit}
      >
        {/* Content for CDrawer */}
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6}>
            <CInput
              label="Name"
              value={fName}
              name="Name"
              onChange={(e) => setFName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CInput
              label="Username"
              value={userName}
              name="user Name"
              onChange={(e) => setUserName(e.target.value)}
            />{" "}
          </Grid>
          <Grid item xs={12} md={6}>
            <CInput
              label="Mobile"
              value={mobile}
              name="mobile"
              onChange={handleMobileChange}
              error={postError} // Pass error state if needed for styling
              helperText={postError}
            />{" "}
        {mobileError && (    <Typography
              sx={{ fontSize: "12px", color: "#FF0000", px: 0.5, pb: 1 }}
            >
              {mobileError}
            </Typography>
        )}
          </Grid>
          <Grid item xs={12} md={6}>
            <CInput
              label="Email"
              value={email}
              type="email"
              name="email"
              onChange={handleEmailChange}
            />
           {emailError && (
            <Typography sx={{ fontSize: "12px", color: "#FF0000", px: 0.5 }}>
              {emailError}
            </Typography>
           )}
          </Grid>
          <Grid item xs={12} md={6} position={"relative"}>
            <CInput
              type={passwordShown ? "text" : "password"}
              label="Password"
              value={password}
              name="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box
              position="absolute"
              top={0}
              right={4}
              // left={0}
              bottom={0}
              sx={{
                margin: "auto",
                transform: "translateY(-50%)",
                top: "50%",
              }}
            >
              {passwordShown ? (
                <LockOpen onClick={toggleButton} style={{ color: "#185aa6" }} />
              ) : (
                <Lock onClick={toggleButton} style={{ color: "#185aa6" }} />
              )}
            </Box>
          </Grid>
        </GridContainer>
      </CDrawer>
      {/* main drawer end  */}
      {/* view drawer  */}
      <Drawer
        anchor={"right"}
        open={openViewDrawer}
        onClose={() => setOpenViewDrawer(false)}
        title={title}
        sx={{width:'400px'}}
      >
         <Box sx={{ display: "flex", alignItems: "center", py: 1, borderBottom: "1px solid #3636363b", }}>
          <IconButton onClick={() => setOpenViewDrawer(false)} size="small">
            <ArrowBack style={{ color: "black" }} />
          </IconButton>
          <Typography
            variant="h6"
            className="nunito_font"
            style={{
              fontSize: "18px",
              fontWeight: 700,
              color: "#185AA6",
            }}
          >
            View Details
          </Typography>
        </Box>
       
        <Box sx={{ width: 400, p: 2 }}>
          <GridContainer>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>First Name</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
                textTransform={"capitalize"}
              >
                {singleData.firstName}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>User Name</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
              >
                {singleData.userName}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Email</Typography>
              <a href={`mailto:${singleData.email}`}
                style={{ paddingLeft: 12, fontSize: "12px",color:'#000' }}
              >
                {singleData.email}
              </a>
            </Grid>
            {/* <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>User Type</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
              >
                {singleData.userType} 
              </Typography>
            </Grid> */}
            {/*  1=superuser, 2=superadmin, 3=admin, 4=user */}
            <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Mobile</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
              >
                {singleData.mobile}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} display={"flex"} flexDirection={"column"}>
              <Typography sx={{ color: "gray" }}>Password</Typography>
              <Typography
                variant="h5"
                component="h2"
                sx={{ px: 1.5, fontSize: "12px" }}
              >
                {singleData.showPassword}
              </Typography>
            </Grid> */}
          </GridContainer>
        </Box>
      </Drawer>
      {/* view drawer end  */}
      <AlertDialog
        onsubmit={handleActiveChange}
        open={openMulitiStatus}
        handleClose={() => setOpenMultistatus(false)}
        text={`Are you sure want to change status  ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openMulitiDelete}
        handleClose={() => setOpenMultiDelete(false)}
        text={`Are you sure want to delete ${selectedId.length} items?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleActiveChange}
        open={openActive}
        handleClose={() => setOpenActive(false)}
        text={`Are you sure want to ${
          singleData.activeStatus !== 1 ? "Active" : "Inactive"
        } ?`}
      ></AlertDialog>
      <AlertDialog
        onsubmit={handleDelete}
        open={openDel}
        handleClose={() => setOpenDel(false)}
        text={"Are you sure want to delete ?"}
      ></AlertDialog>
      {isAlertVisible && (
        <AutoHideAlert
          severity={alertSeverity}
          message={alertMessage}
          autoHideDuration={5000}
          open={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </>
  );
}
