"use client";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Card, Typography } from "@mui/material";
import AuthLogin from "./AuthLogin";
import { axiosPost } from "../../../lib/api";
import AutoHideAlert from "../../../Components/Container/AutoHideAlert";
import Cookies from "js-cookie";
const Login = () => {
  const [postError, setPostError] = useState([]);

  const router = useNavigate();

  const token = Cookies.get("token") !== undefined;
  const ACCESS_TOKEN = Cookies.get("token");
  const userName = Cookies.get("userName");
  const [loginDetails, setLoginDetails] = useState({
    user_name: "",
    show_password: "",
  });

  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const [isForgot, setisForgot] = useState(false);

  const handleLoginDetailsChange = (fieldName, value) => {
    setLoginDetails((prevLoginDetails) => ({
      ...prevLoginDetails,
      [fieldName]: value,
    }));
  };

  const handlePassChange = () => {
    const jsonData = {
      userToken: ACCESS_TOKEN,
      userName: userName,
      currentPassword: password,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };
    try {
      axiosPost
        .post("user/change/password", jsonData)
        .then((response) => {
          if (response.data.message === "Password changed successfully") {
            router("/user");
          } else {
            setPostError(response.data.message);
          }
        })
        .catch((error) => {
          console.error("POST Error:", error);
          setAlertMessage(error.response.data.error);
          setAlertSeverity("error");
          setIsAlertVisible(true);
        });
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  const toggleButton1 = () => {
    setPasswordShown1(!passwordShown1);
  };
  const toggleButton2 = () => {
    setPasswordShown2(!passwordShown2);
  };

  const backgroundImageUrl = "url(/images/bg.png)";

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: { xs: "100vh", md: "100vh" },
          overflow: "hidden",
          background: "#fafafa",
        }}
      >
        <Grid
          container
          spacing={2}
          height={"auto"}
          justifyContent="center"
          sx={{ overflow: "hidden" }}
        >
          {/* <Grid
            item
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            // display="flex"
            sx={{ display: { xs: "none", lg: "flex" } }}
            justifyContent="center"
            alignItems="center"
          >
            <Box
              component={"img"}
              textAlign="center"
              sx={{
                width: "100%",
                position: "relative",
              }}
              src="/images/600x400.png"
            ></Box>
          </Grid> */}
          <Grid
            item
            xs={12}
            sm={12}
            // lg={6}
            // xl={6}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100vh",
              padding: "0px 25px !important",
              // background: "white",
            }}
          >
            <Box
              sx={{
                width: 340,
              }}
              bgcolor={"white"}
              boxShadow={1}
              borderRadius={6}
              p={4}
              position={"relative"}
            >
              <Box
                display={"flex"}
                width={"100%"}
                justifyContent={"center"}
                pb={1}
              >
                <img
                  src="images/logo.png"
                  alt="logo"
                  style={{ height: "32px" }}
                  priority
                />
              </Box>

              {/* <Box my={2}>
                <LogoLite />
              </Box> */}
             <Typography
                variant="h5"
                sx={{ fontWeight: "500", marginBottom: "5px" }}
              >
                Change Password
              </Typography>
              {/* <Typography
                variant="h6"
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  marginBottom: "5px",
                }}
              >
                Welcome back!
              </Typography> */}

              <AuthLogin
                password={password}
                setPassword={setPassword}
                newPass={newPassword}
                setNewPass={setNewPassword}
                confirmPass={confirmPassword}
                setConfirmPass={setConfirmPassword}
                onPassChange={handlePassChange}
                passwordShown1={passwordShown1}
                passwordShown2={passwordShown2}
                toggleButton1={toggleButton1}
                toggleButton2={toggleButton2}
                postError={postError}
              />
            </Box>
            {isAlertVisible && (
              <AutoHideAlert
                severity={alertSeverity}
                message={alertMessage}
                autoHideDuration={3000}
                open={isAlertVisible}
                onClose={() => setIsAlertVisible(false)}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
